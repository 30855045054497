import React from "react"
import Layout from '../components/layout-default'
import { Link } from 'gatsby'

const SalsaWednesdays = () => (
  <Layout
    title="How to Take an Online Dance Class | RF Dance"
    description="" 
    pathname="how-to-take-an-online-dance-class"
    noHero="no-hero">

    <div className="content-block">
      <div className="container">
        <div className="columns">
          <div className="column is-two-thirds display--inline-block content" style={{paddingRight: 0}}>
            <h2 className="has-text-centered">404</h2>
            <div style={{textAlign: `center`, fontSize: `1.2em`, margin: `2em auto 4em`, fontWeight: `600`}}>
              <p>You danced out of bounds. <Link to="/center">We'll lead you back.</Link></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <style jsx>{`
    .content h2 {
      font-size: 120px;
      line-height: 122px;
      margin-top: 30px;
      margin-bottom: 40px;
    }
    .content {
      margin-left: auto;
      margin-right: auto;
    }
    `}</style>
  </Layout>
)

export default SalsaWednesdays